// extracted by mini-css-extract-plugin
export var advantages = "ITPlatform-module--advantages--2b7cf";
export var advantagesIcon = "ITPlatform-module--advantages-icon--c92b8";
export var buttonGetAccess = "ITPlatform-module--button-get-access--92d1d";
export var buttonGetBox = "ITPlatform-module--button-get-box--d4822";
export var buttonGetItemText = "ITPlatform-module--button-get-item-text--aef47";
export var container = "ITPlatform-module--container--bdc8a";
export var fromOctober = "ITPlatform-module--from-october--51279";
export var headerInfo = "ITPlatform-module--header-info--2744a";
export var image = "ITPlatform-module--image--2ef49";
export var infoContainer = "ITPlatform-module--info-container--b7085";